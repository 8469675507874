body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ff5fa0 #00e8ff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #00e8ff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ff5fa0;
  border-radius: 20px;
  border: 3px solid #00e8ff;
}
